















import { Component, Mixins, Vue } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'

@Component({
  components: {
    ModalBase,
    ButtonBase,
    InputWithLabel,
  },
})
export default class ModalAdminJukuNameEdit extends Mixins(ModalBaseMethod) {
  private isProcessing = false

  private jukuName = ''

  private jukuCode = ''

  private id = 0

  /**
   * バリデーションエラー
   */
  private get nameErrors(): string[] {
    const errors = []
    if (this.jukuName !== '') {
      if (this.jukuName.length > 50) {
        errors.push('塾名は50文字までです')
      }
    }
    return errors
  }

  /**
   * ボタンの色（ボタン操作制御）
   */
  private get colortype(): string {
    return this.jukuName.length > 0 && this.nameErrors.length === 0 ? 'green' : 'pointer-events-none'
  }

  public async loadAcademy(id: number) {
    if (this.isProcessing) return
    this.isProcessing = true

    this.id = id

    await Vue.prototype.$http.httpWithToken
      .get(`/academies/${this.id}`)
      .then((res: any) => {
        this.jukuName = res.data.name
        this.jukuCode = res.data.code
        this.show()
      })
      .catch((error: any) => {
        if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        }
      })
      .finally(() => {
        this.isProcessing = false
      })
  }

  private async updateAcademy() {
    if (this.isProcessing) return
    this.isProcessing = true

    await Vue.prototype.$http.httpWithToken
      .patch(`/academies/${this.id}`, {
        name: this.jukuName,
      })
      .then(() => {
        alert('塾名が更新されました。')
        this.$emit('hide-model')
        this.hide()
      })
      .catch((error: any) => {
        if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        }
      })
      .finally(() => {
        this.isProcessing = false
      })
  }
}
