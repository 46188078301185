
























import { Component, Mixins, Vue } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'

@Component({
  components: {
    ModalBase,
    ButtonBase,
    InputWithLabel,
  },
})
export default class ModalAdminJukuAdd extends Mixins(ModalBaseMethod) {
  private isProcessing = false

  private jukuDatas: { code: ''; name: '' }[] = [{ code: '', name: '' }]

  /**
   * 入力値のバリデーションエラー
   */
  private get errors(): { code: { index: number; error: string }[]; name: { index: number; error: string }[] } {
    const errors = {
      code: [] as { index: number; error: string }[],
      name: [] as { index: number; error: string }[],
    }

    for (let i = 0; i < this.jukuDatas.length; i++) {
      const juku: { code: string; name: string } = this.jukuDatas[i]

      if (juku.code !== '') {
        if (!juku.code.match(/^[a-zA-Z0-9]+$/)) {
          errors.code.push({ index: i, error: '塾コードは半角英数字で入力してください' })
        }
        if (juku.code.length > 20) {
          errors.code.push({ index: i, error: '塾コードは20文字までです' })
        }
      }

      if (juku.name !== '') {
        if (juku.name.length > 50) {
          errors.name.push({ index: i, error: '塾名は50文字までです' })
        }
      }
    }

    return errors
  }

  // eslint-disable-next-line
  private get codeErrors(): any {
    return (index: number) => {
      return this.errors.code.filter((err) => err.index === index).map((obj) => obj.error)
    }
  }

  // eslint-disable-next-line
  private get nameErrors(): any {
    return (index: number) => {
      return this.errors.name.filter((err) => err.index === index).map((obj) => obj.error)
    }
  }

  /**
   * ボタンの色（ボタン操作制御）
   */
  private get colortype(): string {
    const permitted =
      this.jukuDatas.length > 0 &&
      this.jukuDatas.filter((juku) => juku.code === '' || juku.name === '').length === 0 &&
      this.errors.code.length === 0 &&
      this.errors.name.length === 0

    return permitted ? 'green' : 'pointer-events-none'
  }

  private addRow(): void {
    this.jukuDatas.push({ code: '', name: '' })
  }

  private removeRow(index: number): void {
    this.jukuDatas.splice(index, 1)
  }

  /**
   * 塾を追加するメソッド
   */
  private async createAcademies() {
    if (this.isProcessing) return
    this.isProcessing = true

    await Vue.prototype.$http.httpWithToken
      .post('/academies', this.jukuDatas)
      .then(() => {
        alert('塾の追加が完了しました')
        this.jukuDatas = [{ code: '', name: '' }]
        this.$emit('hide-model')
        this.hide()
      })
      .catch((error: any) => {
        if (error.response.data.status === 409) {
          alert('塾コードが重複しているため登録できません。')
        }
      })
      .finally(() => {
        this.isProcessing = false
      })
  }
}
