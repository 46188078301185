
import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment'

// mixinして使用する
@Component
export default class LocalMoment extends Vue {
  protected today(): moment.Moment {
    return moment().locale('ja')
  }

  /**
   * ${months}ヶ月前のMomentオブジェクトを返却する
   * @param months number
   */
  protected beforeMonths(months: number): moment.Moment {
    return this.today().subtract(months, 'months')
  }

  /**
   * ${days}日前のMomentオブジェクトを返却する
   * @param days number
   */
  protected beforeDays(days: number): moment.Moment {
    return this.today().subtract(days, 'days')
  }

  /**
   * 日付文字列を、別のフォーマットに変換する
   */
  protected reformatDate(dateStr: string, currentFormat: string, newFormat: string) {
    return moment(dateStr, currentFormat).locale('ja').format(newFormat)
  }

  // date文字列とtime文字列からオブジェクトを返す
  protected createDateTimeFromString(datetime: { date: string; time: string }): moment.Moment | null {
    if (datetime.date === '' || datetime.time === '') return null
    return moment(`${datetime.date} ${datetime.time}`).locale('ja')
  }

  protected formatDateTimeFromString(datetime: { date: string; time: string }): string {
    const dt = this.createDateTimeFromString(datetime)
    return dt ? dt.format() : ''
  }

  protected formatDateTimeFromDateObj(date: Date | null): { date: string; time: string } {
    if (!date) return { date: '', time: '' }
    return { date: moment(date).format('YYYY/MM/DD'), time: moment(date).format('HH:mm') }
  }

  protected afterEqualThanToday(date: Date | null): boolean {
    if (!date) return false
    // 現在日<=指定日 を (現在日 - 1日)<指定日として実装する
    const yesterday = this.beforeDays(1)
    return moment(date).locale('ja').isAfter(yesterday, 'day')
  }

  protected afterThanToday(date: Date | null): boolean {
    if (!date) return false
    return moment(date).locale('ja').isAfter(this.today(), 'day')
  }

  protected equalToday(date: Date | null): boolean {
    if (!date) return false
    return moment(date).locale('ja').isSame(this.today(), 'day')
  }
}
